import img1 from '../../images/svg-1.svg'
import img2 from '../../images/svg-6.svg'

export const homeObjOne={
id:'nosotros',
lightBg:false,
LightText:true,
LightTextDesc:true,
topLine:'COBINSAC',
headLine:'Lideres en cobranzas',
description:'Nuestro equipo, está conformado por un grupo de profesionales con amplia trayectoria vinculada al tema de las cobranzas desarrollada en diversas instituciones financieras, comerciales y de servicios.',
buttonLabel:'Conocer mas',
imgStart:false,
img:img1,
alt:'Call',
dark:true,
primary:true,
darkText:false
};

export const homeObjTwo={
id:'CRM',
lightBg:false,
LightText:true,
LightTextDesc:true,
topLine:'COBINSAC CRM',
headLine:'¡CRM propio para agilizar las negociones y super amigable!',
description:'Utilizamos un CRM , que permite implementar y gestionar un Contact Center administrando operaciones entrantes y salientes, con acceso a métricas, reportes e indicadores, supervisión real-time de agentes y demás funcionalidades avanzadas de outbound e inbound.',
buttonLabel:'conocer mas',
imgStart:true,
img:img2,
alt:'Contacto',
dark:true,
primary:true,
darkText:false
};

 



