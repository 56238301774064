import React,{useState,useEffect} from 'react';
import {FaBars} from 'react-icons/fa';
import logo from '../../images/Cobinsac_icon.png'
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import {Nav,
    NavbarContainer,
    NavLogo,
    MobileIcon,
    NavItem,
    NavMenu,
    NavLinks,
    NavBtn,
    NavBtnLink,Img} from './NavbarElements';

const Navbar = ({toggle}) => {
    const [scrollNav,setScrollNav]=useState(false)

    const changedNav=()=>{
        if(window.scrollY>=80){
                setScrollNav(true)
            }else{
                setScrollNav(false)
            }
    };

    useEffect(()=>{
        window.addEventListener('scroll',changedNav)
    },[]);

    const toggleHome=()=>{
        scroll.scrollToTop();
    };

    return (
       <>
       <IconContext.Provider value={{color:'#fff'}}/>
       <Nav scrollNav={scrollNav}>
        <NavbarContainer>
        <NavLogo to='/' onClick={toggleHome}><Img src={logo}></Img>
            COBINSAC</NavLogo>   
        <MobileIcon onClick={toggle}>
            <FaBars/>
        </MobileIcon>
        <NavMenu>
            <NavItem>
                <NavLinks to='/' onClick={toggleHome} >INICIO</NavLinks>
            </NavItem>
            <NavItem>
                <NavLinks to='nosotros' smooth={true} duration={500} spy={true} exact='true' offset={-80} >NOSOTROS</NavLinks>
            </NavItem>
            <NavItem>
                <NavLinks to='servicios' smooth={true} duration={500} spy={true} exact='true' offset={-80}>SERVICIOS</NavLinks>
            </NavItem>
            <NavItem>
                <NavLinks to='tecnologia' smooth={true} duration={500} spy={true} exact='true' offset={-80}>TECNOLOGIA</NavLinks>
            </NavItem>
            <NavItem>
                <NavLinks to='contactenos' smooth={true} duration={500} spy={true} exact='true' offset={-80}>CONTACTENOS</NavLinks>
            </NavItem>
          </NavMenu>
       
        </NavbarContainer>
       </Nav>
       </>
    )
}

export default Navbar
