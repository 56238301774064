import React from 'react'
import Video from '../../videos/video_2.mp4';
import { BotContainer,BotBg,VideoBg,BotContent,BotH1,BotP,BotBtnWrapper, ArrowForward, ArrowRight,SocialIcons} from './BotElements';
import { FaQrcode } from 'react-icons/fa';


const BotSection = () => {
   
  return (

    <BotContainer >
        <BotBg>
            <VideoBg src={Video} autoPlay loop muted type='video/mp4' />
        </BotBg>
        <BotContent>
            <SocialIcons>
            <FaQrcode></FaQrcode>
            </SocialIcons>
          
         <BotH1>Cobranza Inteligente</BotH1>
                  <BotP>
                  Buscamos adaptarnos y adelantarnos a las diferentes necesidades de nuestros clientes, entregando soluciones estratégicas que se integren al uso de medios y canales de contacto.
          </BotP>
        </BotContent>

      </BotContainer>


  );
};

export default BotSection;

