import './App.css';
import {BrowserRouter as Router,Routes,Route} from 'react-router-dom';
import Home from './pages';
import SolucionesPage from './pages/Solucion';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/Soluciones" element={<SolucionesPage/>} />
      </Routes>
    </Router>
  );
}

export default App;
