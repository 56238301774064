import React from 'react';
import Icon1 from '../../images/svg-2.svg';
import Icon2 from '../../images/svg-3.svg';
import Icon3 from '../../images/svg-4.svg';
import {ServicesContainer,ServicesH1,ServicesWrapper,ServicesCard,ServicesIcon,ServicesH2,ServicesP} from './ServicesElements';


const Services = () => {
  return (
  
  <ServicesContainer>
      <ServicesH1 id='servicios'>Nuestros Servicios</ServicesH1>
      <ServicesWrapper>
          <ServicesCard>
              <ServicesIcon src={Icon1}/>
              <ServicesH2>Cobranza Preventiva</ServicesH2>
              <ServicesP>Cobranza que se realiza a los clientes antes del vencimiento de su cuota, con la finalidad de que mantenga su crédito al día.</ServicesP>
          </ServicesCard>
          <ServicesCard>
              <ServicesIcon src={Icon2}/>
              <ServicesH2>Cobranza Judicial</ServicesH2>
              <ServicesP>Cobranza durante la etapa extrajudicial no se obtienen resultados óptimos, contamos con un Staff de Abogados.</ServicesP>
          </ServicesCard>
          <ServicesCard>
              <ServicesIcon src={Icon3}/>
              <ServicesH2>Cobranza Extrajudicial</ServicesH2>
              <ServicesP>cobranza que se realiza a clientes con una o mas cuotas atrasadas, a través de visitas personalizadas por nuestros gestores de cobranza domiciliaria.</ServicesP>
          </ServicesCard>
      </ServicesWrapper>
  </ServicesContainer>
  
  
  );
};

export default Services;
