import React,{useState} from 'react';
import Video from '../../videos/video.mp4';
import {Button} from '../ButtonElements';
import { HeroContainer,HeroBg,VideoBg,HeroContent,HeroH1,HeroP,HeroBtnWrapper, ArrowForward, ArrowRight} from './HeroElements';


const HeroSection = () => {
   
  const [hover,setHover]=useState(false)

const onHover=()=>{
  setHover(!hover)
}

  return (

    <HeroContainer >
        <HeroBg>
            <VideoBg src={Video} autoPlay loop muted type='video/mp4' />
        </HeroBg>
        <HeroContent>
          <HeroH1>Somos la solución a sus problemas</HeroH1>
          <HeroP>
          Ser la empresa de cobranza líder, por su competitividad, la calidad de servicio, asegurando a nuestros clientes un trabajo eficiente, responsable y efectivo.
          </HeroP>
          <HeroBtnWrapper>
            <Button to="signup" onMouseEnter={onHover} onMouseLeave={onHover} primary='true' dark='true'>
              Iniciar {hover ? <ArrowForward/>:<ArrowRight/>}
            </Button>
          </HeroBtnWrapper>
        </HeroContent>
      </HeroContainer>


  );
};

export default HeroSection;
