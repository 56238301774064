import React from 'react'
import Icon1 from '../../images/wsp.png';
import Icon2 from '../../images/central.png';
import Icon3 from '../../images/correo.png';
import Icon4 from '../../images/web.png';
import { StatsContainer,StatsH1,StatsWrapper,StatsCard,StatsIcon,StatsH2,StatsP } from './StatsElemtens'

const Stats = () => {
  return (
    
  <StatsContainer>
  <StatsH1 id='contactenos'>COMUNICATE A TRAVES DE NUESTROS CANALES DE ATENCION</StatsH1>
  <StatsWrapper>
      <StatsCard>
          <StatsIcon src={Icon1}/>
          <StatsH2>Linea Whatsapp Empresarial</StatsH2>
          <StatsP>Comunicarse mediante Whatsapp con nosotros al +51 940296625.</StatsP>
      </StatsCard>
      <StatsCard>
          <StatsIcon src={Icon2}/>
          <StatsH2>Central Telefonica</StatsH2>
          <StatsP>Comunicarse con nuestra central Telefonica +01 321-9120</StatsP>
      </StatsCard>
      <StatsCard>
          <StatsIcon src={Icon3}/>
          <StatsH2>Correo Empresarial</StatsH2>
          <StatsP>Si desea mas informacion de nuestros servicios enviar un correo: administracion@cobranzasintegrales.com.pe</StatsP>
      </StatsCard>
      <StatsCard>
          <StatsIcon src={Icon4}/>
          <StatsH2>Pagina Web</StatsH2>
          <StatsP>Si desea mas informacion de nuestros servicios puede consultar en nuestra pagina web mediante el chat bot</StatsP>
      </StatsCard>
  </StatsWrapper>
</StatsContainer>
  );
};

export default Stats