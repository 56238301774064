import styled from "styled-components";

export const StatsContainer=styled.div`
height:950px;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
background:#f9f9f9;


@media screen and (max-width:768px){
    height:1700px;

}

@media screen and (max-width:480px){
    height:2000px;
}
`;

export const StatsWrapper=styled.div`
max-width:1080px;
margin:0 auto;
display:grid;
grid-template-columns:1fr 1fr 1fr 1fr;
align-items:center;
grid-gap:16px;


@media screen and (max-width:1080px){
    grid-template-columns: 1fr 1fr;
}

@media screen and (max-width:768px){
    grid-template-columns:1fr;
  
}
`;

export const StatsCard=styled.div`
background:#F5F8FF;
display:flex;
flex-direction:column;
justify-content:flex-start;
align-items:center;
border-radius:10px;
height:340px;
padding:10px;
box-shadow: 0 1px 3px rgba(0,0,0,0.2);
transition:all 0.2s ease-in-out;

&:hover{
    transform:scale(1.02);
    transition:all 0.2s ease-in-out;
    cursor:pointer;
}
`;

export const StatsIcon=styled.img`
height:160px;
width:160px;
margin-bottom:30px;
`;

export const StatsH1=styled.h1`
font-size:2.5rem;
color:#072146;
margin-bottom:64px;
text-align:center;

@media screen and (max-width:480px){
    font-size:2rem
}
`;

export const StatsH2=styled.h2`
font-size:0.9rem;
margin-bottom:15px;
`;

export const StatsP=styled.p`
font-size:0.67rem;
text-align:center;
`