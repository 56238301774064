import React from 'react';
import { FaFacebook, FaInstagram,FaYoutube,FaWhatsapp } from 'react-icons/fa';
import {animateScroll as scroll} from 'react-scroll';
import logo from '../../images/logo_cobinsac.png'

import { 
    FooterContainer,
    FooterWrap,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinkItems,
    FooterLinkTitle,
    FooterLink,
    FooterLinkS,
    SocialMedia,
    SocialMediaWrap,
    SocialLogo,
    WebSiteRights,
    SocialIcons,
    SocialIconLink,
    Img} from './FooterElements';



const Footer = () => {
    const toggleHome=()=>{
        scroll.scrollToTop();
    };
  return (
    <FooterContainer>
        <FooterWrap>
            <FooterLinksContainer>
            <FooterLinksWrapper>
                    
                        <Img src={logo}></Img>
                                
    
                </FooterLinksWrapper>
                <FooterLinksWrapper>
                    <FooterLinkItems>
                        <FooterLinkTitle>INTERES</FooterLinkTitle>
                            <FooterLinkS to='nosotros'>Nosotros</FooterLinkS>
                            <FooterLinkS to='servicios'>Servicios</FooterLinkS>
                            <FooterLinkS to='contacto'>Contacto</FooterLinkS>
                            <FooterLinkS to='tecnologia'>Tecnologias</FooterLinkS>
                    </FooterLinkItems>
                </FooterLinksWrapper>
                <FooterLinksWrapper>
                    <FooterLinkItems>
                        <FooterLinkTitle>ABOUT US</FooterLinkTitle>
                            <FooterLink to=''>HOW IT WORKS</FooterLink>
                            <FooterLink to=''>TESTIMONIALS</FooterLink>
                            <FooterLink to=''>CARRERS</FooterLink>
                            <FooterLink to=''>INVESTORS</FooterLink>
                            <FooterLink to=''>TERMS OF SERVICE</FooterLink>  
                    </FooterLinkItems>
                </FooterLinksWrapper>
            </FooterLinksContainer>
            <SocialMedia>
                <SocialMediaWrap>
                    <SocialLogo to='/' onClick={toggleHome}>
                        cobinsac
                    </SocialLogo>
                    <WebSiteRights>cobinsac © {new Date().getFullYear()} All rights reserved.</WebSiteRights>
                    <SocialIcons>
                        <SocialIconLink href="/" target="_blank" aria-label="Facebook">
                            <FaFacebook>
                            </FaFacebook>
                        </SocialIconLink>
                        <SocialIconLink href="/" target="_blank" aria-label="Instragram">
                            <FaInstagram>
                            </FaInstagram>
                        </SocialIconLink>
                        <SocialIconLink href="/" target="_blank" aria-label="Youtube">
                            <FaYoutube>
                            </FaYoutube>
                        </SocialIconLink>
                        <SocialIconLink href="https://wa.me/51913643365?text=Hola" target="_blank" aria-label="Whatssap">
                            <FaWhatsapp>
                            </FaWhatsapp>
                        </SocialIconLink>
                    </SocialIcons>
                </SocialMediaWrap>
            </SocialMedia>
        </FooterWrap>
    </FooterContainer>

  );
};

export default Footer;
