import React from 'react';
import Icon1 from '../../images/svg-7.svg';
import Icon2 from '../../images/svg-8.svg';
import Icon3 from '../../images/svg-9.svg';
import {TegnologysContainer,TegnologysH1,TegnologysWrapper,TegnologysCard,TegnologysIcon,TegnologysH2,TegnologysP} from './TecnologysElements';


const Tecnologys = () => {
  return (
  
  <TegnologysContainer>
      <TegnologysH1 id='tecnologia'>Nueva Generación de Tecnologias</TegnologysH1>
      <TegnologysWrapper>
          <TegnologysCard>
              <TegnologysIcon src={Icon1}/>
              <TegnologysH2>Chat Bot</TegnologysH2>
              <TegnologysP>Nuestra tecnología ChatBot permite atender a los clientes de manera ágil y sin interrupciones.</TegnologysP>
          </TegnologysCard>
          <TegnologysCard>
              <TegnologysIcon src={Icon2}/>
              <TegnologysH2>PowerBI</TegnologysH2>
              <TegnologysP>Los datos se muestran de manera interactiva y personalizada.Ordenador, tablet o móvil, a la medida de cada negocio</TegnologysP>
          </TegnologysCard>
          <TegnologysCard>
              <TegnologysIcon src={Icon3}/>
              <TegnologysH2>Machine Learning</TegnologysH2>
              <TegnologysP>Ofrece una atención personalizada, ágil e inteligente, logrando solucionar más del 50% de las llamadas sin necesidad de interacción humana.</TegnologysP>
          </TegnologysCard>
      </TegnologysWrapper>
  </TegnologysContainer>
  
  
  );
};

export default Tecnologys;