import React from 'react'
import { SidebarContainer,Icon,CloseIcon,SidebarWrapper,SidebarMenu,SiderbarLink } from './SidebarElements';

const Sidebar = ({isOpen,toggle}) => {
    return (
     <SidebarContainer isOpen={isOpen} onClick={toggle}>
         <Icon onClick={toggle}>
             <CloseIcon/>
         </Icon>
         <SidebarWrapper>
             <SidebarMenu>
                 <SiderbarLink to="inicio" onClick={toggle}>
                     INICIO
                 </SiderbarLink>
                 <SiderbarLink to="nosotros" onClick={toggle}>
                     NOSOTROS
                 </SiderbarLink>
                 <SiderbarLink to="servicios" onClick={toggle}>
                     SERVICIOS
                 </SiderbarLink>
                 <SiderbarLink to="contacto" onClick={toggle}>
                     CONTACTO
                 </SiderbarLink>
                 <SiderbarLink to="tecnologia" onClick={toggle}>
                     TECNOLOGIA
                 </SiderbarLink>
             </SidebarMenu>
         </SidebarWrapper>
     </SidebarContainer>
    )
}

export default Sidebar
